var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"md","title":_vm.type ==='add'?'Thêm thiết bị' :'Chỉnh sửa thiết bị',"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateEquipmentBusiness,"hidden":_vm.resetState}},[_c('validation-observer',{ref:"rule"},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Mã hiệu "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":_vm.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Mã hiệu","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.code),callback:function ($$v) {_vm.$set(_vm.dataInput, "code", $$v)},expression:"dataInput.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Danh mục thiết bị "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"types","rules":"required","custom-messages":_vm.types},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","reduce":function (value) { return value.id; },"label":"name","options":_vm.dataComboboxEquipBusiness || [],"placeholder":"Danh mục thiết bị"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(option.name)+" ")])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.indexEquipmentId),callback:function ($$v) {_vm.$set(_vm.dataInput, "indexEquipmentId", $$v)},expression:"dataInput.indexEquipmentId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Số lượng "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"quantity","rules":"required","custom-messages":_vm.quantity},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Số lượng","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.quantity),callback:function ($$v) {_vm.$set(_vm.dataInput, "quantity", $$v)},expression:"dataInput.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Ngày tạo"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"createdDay","rules":"required","custom-messages":_vm.createdDay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"disabled":false,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.createdDay),callback:function ($$v) {_vm.$set(_vm.dataInput, "createdDay", $$v)},expression:"dataInput.createdDay"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Nước sản xuất")]),_c('b-form-input',{attrs:{"placeholder":"Nước sản xuất"},model:{value:(_vm.dataInput.country),callback:function ($$v) {_vm.$set(_vm.dataInput, "country", $$v)},expression:"dataInput.country"}})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Đặc tính kĩ thuật")]),_c('b-form-textarea',{attrs:{"placeholder":"Đặc tính kĩ thuật","no-resize":"","rows":"3"},model:{value:(_vm.dataInput.technical),callback:function ($$v) {_vm.$set(_vm.dataInput, "technical", $$v)},expression:"dataInput.technical"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }