<template>
  <div id="inspectorId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm thiết bị'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataList || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'created'">
            {{ props.row.created | formatDateToDDMM }}
          </span>
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                icon="Edit3Icon"
                size="18"
                class="text-body"
                @click="showModalEdit(props.row.id)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <edit-equip
        :id="modalEditEquip"
        :type="modalType"
        :dataDetailEquipment="dataDetailEquipment"
        @handleCreateEquipmentBusiness="handleCreateEquipmentBusiness"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditEquip from './components/EditEquip.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    EditEquip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      totalRecord: 0,
      dataList: [],
      columns: [
        {
          label: 'MÃ THIẾT BỊ',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN THIẾT BỊ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'MÃ HIỆU',
          field: 'deviceCode',
          sortable: false,
        },
        {
          label: 'ĐẶC TÍNH KĨ THUẬT',
          field: 'technical',
          sortable: false,
        },
        {
          label: 'NƯỚC SẢN XUẤT',
          field: 'country',
          sortable: false,
        },
        {
          label: 'NGÀY TẠO',
          field: 'created',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalEditEquip: 'comfirm-create-edit',
      deleteIds: [],
      showBtnMultiDelete: false,
      modalType: '',
      equipmentId: null,
      dataDetailEquipment: {},
      arrayExcel: [
        'code',
        'indexEquipment',
        'quantity',
        'country',
        'technical',
      ],
    }
  },
  mounted() {
    this.fetchDataComboboxEquipBusiness()
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('equipmentBusiness', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
      'fetchDataComboboxEquipBusiness',
    ]),

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_EQUIPMENT_BUSINESS, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEditEquip)
    },
    // click show modal sửa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.equipmentId = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_EQUIPMENT_BUSINESS}${this.equipmentId}`)
      this.dataDetailEquipment = data
      this.$bvModal.show(this.modalEditEquip)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    async handleCreateEquipmentBusiness(val) {
      const payload = {
        ...val,
        created: val.createdDay,
        quantity: parseInt(val.quantity, 10),
        quantityUsing: val.isUsing === true ? parseInt(val.quantityUsing, 10) : 0,
      }
      if (this.modalType === 'edit') {
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_EQUIPMENT_BUSINESS, { ...payload, id: this.equipmentId }).then(response => {
          this.$hideAllPageLoading()
          if (response.status === 200) {
            this.$root.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditEquip)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }

      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_EQUIPMENT_BUSINESS, payload).then(response => {
          this.$hideAllPageLoading()
          if (response.status === 200) {
            this.$root.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditEquip)
          }
          this.fetchData(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_EQUIPMENT_BUSINESS, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-equipment-business' })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
