<template>
  <b-modal
    :id="id"
    size="md"
    :title="type ==='add'?'Thêm thiết bị' :'Chỉnh sửa thiết bị'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateEquipmentBusiness"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Mã hiệu <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="code"
          rules="required"
          :custom-messages="code"
        >
          <b-form-input
            v-model="dataInput.code"
            placeholder="Mã hiệu"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Danh mục thiết bị <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="types"
          rules="required"
          :custom-messages="types"
        >
          <v-select
            id="type"
            v-model="dataInput.indexEquipmentId"
            :reduce="value => value.id"
            label="name"
            :options="dataComboboxEquipBusiness || []"
            placeholder="Danh mục thiết bị"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              {{ option.name }}
            </template>
            <template #selected-option-container="{ option }">
              <div class="vs__selected">
                {{ option.name }}
              </div>
            </template>
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Số lượng <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="quantity"
          rules="required"
          :custom-messages="quantity"
        >
          <b-form-input
            v-model="dataInput.quantity"
            type="number"
            placeholder="Số lượng"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Ngày tạo<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="createdDay"
          rules="required"
          :custom-messages="createdDay"
        >
          <date-time-picker
            v-model="dataInput.createdDay"
            :disabledInput="true"
            :disabled="false"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Nước sản xuất</label>
        <b-form-input
          v-model="dataInput.country"
          placeholder="Nước sản xuất"
        />
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Đặc tính kĩ thuật</label>
        <b-form-textarea
          v-model="dataInput.technical"
          placeholder="Đặc tính kĩ thuật"
          no-resize
          rows="3"
        />
      </b-form-group>

      <!-- <b-form-group
        class="mt-1"
        label-for="user"
      >
        <b-form-checkbox
          id="checkbox-1"
          v-model="dataInput.isUsing"
          class="mt-1"
          name="checkbox-1"
        >
          Sử dụng thiết bị
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="dataInput.isUsing"
        class="mt-1"
        label-for="user"
      >
        <label for="user">Số lượng thiết bị sử dụng <span class="text-danger">(*)</span></label>
        <b-form-input
          v-model="dataInput.quantityUsing"
          type="number"
          placeholder="Nước sản xuất"
        />
      </b-form-group> -->
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    DateTimePicker,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: 'edit-equip',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetailEquipment: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      dataInput: {
        code: '',
        indexEquipmentId: '',
        quantity: '',
        country: '',
        technical: '',
        createdDay: new Date().toISOString(),
        // isUsing: false,
        // quantityUsing: 0,
      },
      code: {
        required: 'Mã hiệu là bắt buộc',
      },
      types: {
        required: 'Danh mục thiết bị là bắt buộc',
      },
      quantity: {
        required: 'Số lượng là bắt buộc',
      },
    }
  },
  computed: {
    ...mapGetters('equipmentBusiness', ['dataComboboxEquipBusiness']),
  },
  watch: {
    dataDetailEquipment(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetailEquipment.code,
          indexEquipmentId: this.dataDetailEquipment.indexEquipmentId,
          quantity: this.dataDetailEquipment.quantity,
          country: this.dataDetailEquipment.country,
          technical: this.dataDetailEquipment.technical,
          createdDay: this.dataDetailEquipment.created,
          // isUsing: this.dataDetailEquipment.isUsing,
          // quantityUsing: this.dataDetailEquipment.quantityUsing,
        }
      }
    },
  },

  methods: {
    handleCreateEquipmentBusiness(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateEquipmentBusiness', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        code: '',
        indexEquipmentId: '',
        quantity: '',
        country: '',
        technical: '',
        // isUsing: false,
      }
      // if (this.dataInput.isUsing === false) {
      //   this.dataInput.quantityUsing = 0
      // }
    },
  },
}
</script>
